<div class="d-flex">
  <div class="w-100">
    <div class="d-flex mt-2">
      <div class="toast-title w-100" aria-label="warning">{{title}}</div>
      @if (titleButton) {
        <button class="toast-title-button col-4" (click)="titleButton.handler()">
          {{titleButton.text}}
        </button>
      }
    </div>
    @if (message) {
      <div role="alertdialog" aria-live="polite" class="toast-message">
        @if (options.enableHtml) {
          <div
            role="alert"
            aria-live="polite"
            [class]="options.messageClass"
            [innerHTML]="message"
          ></div>
        }
        @if (!options.enableHtml) {
          <div
            role="alert"
            aria-live="polite"
            [class]="options.messageClass"
            [attr.aria-label]="message"
            >
            {{ message }}
          </div>
        }
      </div>
    }
  </div>

  <div>
    <button aria-label="Close" class="toast-close-button"></button>
  </div>
</div>

@if (buttons.length) {
  <div class="btn-container d-flex mt-4 mr-4">
    @for (button of buttons; track button) {
      <button
        class="col"
        [ngClass]="button.class || 'btn-primary'"
        (click)="button.handler()"
        >
        {{button.text}}
      </button>
    }
  </div>
}
