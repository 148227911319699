<form [formGroup]="form" class="w-100">
  <div class="vin-box-field pt-4">
    <div class="input-label">Vehicle type</div>
    <div ngbDropdown class="btn-dropdown">
      <button
        class="details-input form-control d-flex align-items-center justify-content-between text-left"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        {{ form.get('vehicleType').value }}

        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="dropdownBasic1">
        @for (option of sortOptions; track option) {
        <button ngbDropdownItem class="dropdown px-3" (click)="changeVehicleType(option)">{{ option }}</button>
        }
      </div>
    </div>
  </div>
  @if(form.get('vehicleType').value === 'RV'){
  <div class="my-4 w-100">
    <app-options-slide
      [(selected)]="selectedOption"
      [optionItemLight]="true"
      [options]="inputOptions"
      (selectedChange)="onSelectedOptionChange($event)"
    ></app-options-slide>
  </div>
  }

  <div class="vin-box-field mt-4">
    <div class="input-label">VIN</div>
    <div class="vin-entry-block">
      <input
        class="vin-entry form-control"
        [readonly]="isListingLive"
        type="text"
        name="vin-entry"
        formControlName="vin"
        placeholder="Enter VIN"
        [ngClass]="{'disabled-feilds' : isListingLive, 'need-input' : checkTyposUi}"
      />
      @if (f.vin.dirty) {
      <img
        [src]="f.vin.valid? 'assets/VerifiedVin.svg' : 'assets/vinError.svg'"
        class="vin-svg mt-1"
        alt="vin verification"
      />
      }
    </div>
  </div>

  @if (vinIsShort && searchPressed) {
  <div class="vin-vintage mb-3">VIN too short. VIN should be 17 digits.</div>
  }

  <div class="vin-box-field mx-4 mt-4">
    <button class="btn btn-primary w-100" type="submit" name="submit" value="search" (click)="lookUpListingInfo()">
      Continue
    </button>
  </div>
  @if (isListingLive) {
  <div class="vin-note mt-4">
    <p>Your vin number cannot be edited once your listing is live</p>
  </div>
  }
</form>

<app-modal-vintage-vehicle
  [vin]="f.vin.value"
  [(isOpen)]="showModalVintageVehicle"
  (onContinue)="onVintageVehicleSave($event)"
  (checkTypos)="checkTypos()"
></app-modal-vintage-vehicle>
