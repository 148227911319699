import { InjectionToken } from '@angular/core';

export interface IPreLoginConfig {
  env: string;
  production: boolean;
  services: {
    useInspection: boolean;
    carlifeActive: boolean;
  };

  facebookPixelId: string;
  amplifyRegion: string;
  amplifyIdentityPoolId: string;
  amplifyUserPoolId: string;
  amplifyWebClientId: string;
  amplifyOauthDomain: string;
}

export interface IPostLoginConfig {
  posthogKey: string;
  posthogHost: string;
  googleApiKey: string;
  uploadBucketUrl: string;
  uploadEndpointUrl: string;
  chatKittyApiKey: string;
  chatKittyHost: string;
  vouchedPrivateKey: string;
  vouchedWebhookUrl: string;
  initialFeePrice: number;
  listingPrice: number;
  payLaterPrice: number;
}

export interface IBaseConfig {
  env: string;
  production: boolean;
  apiUrl: string;
  pdfProxyUrl: string;
  socketUrl: string;
  docUrl: string;
  appUrl: string;
  websiteUrl: string;
  blogUrl: string;
  blogKey: string;
  parentUrl: string;
  googleMapsApiUrl2: string;
  googleMapsApiUrl: string;
  airbrakeProject: string;
  airbrakeKey: string;
  oneSignalAppId: string;
  vouchedUrl: string;
  vouchedPublicKey: string;
  plaidEnvironment: string;
  plaidPublicKey: string;
  vehicleVinApi: string;
  appDomain: string;
  staticUrl: string;
  stripeKey: string;
  failed: boolean;
  rosterKey: string;
  rosterIntegrity: string;
  carWiserToken: string;
  carWiserURL: string;
  insurifyRoute: string;
  externalScripts: {
    trackDeskOriginId: string;
  };
  stripeProducts: {
    vehicleReportProductId: string;
  };
}

export type IAppConfig = IPostLoginConfig & IBaseConfig & IPreLoginConfig;
export const APP_CONFIG = new InjectionToken<IAppConfig>('app-config');

export function setBaseConfig(initial: IBaseConfig): IBaseConfig {
  const base = window.location.hostname;
  return {
    ...initial,
    parentUrl: initial.parentUrl || `https://${base}`,
    appUrl: initial.appUrl || `https://app.${base}`,
    apiUrl: initial.apiUrl || `https://${base}/api`,
    websiteUrl: initial.websiteUrl || `https://${base}`,
    appDomain: initial.appDomain || `${base}`,
  };
}
