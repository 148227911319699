<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mb-4">{{title}}</div>
  <div class="body">
    <label for="" class="mb-2"> Enter the 4-digit code sent to {{phone | mask: '+0 (000) 000-0000'}} </label>
    <form class="form-group mb-3" [formGroup]="form">
      <div class="d-flex justify-content-between">
        <div class="pr-3">
          <input
            #char1
            type="text"
            maxlength="4"
            formControlName="char1"
            autofocus
            class="char1 form-control"
            (input)="inputText($event,'char1', 'char2')"
            (paste)="onPaste($event)"
            inputmode="numeric"
            autocomplete="one-time-code"
            />
        </div>
        <div class="pr-3">
          <input
            type="text"
            maxlength="1"
            #char2
            formControlName="char2"
            class="char2 form-control"
            (input)="inputText($event, 'char2', 'char3', 'char1')"
            (paste)="onPaste($event)"
            inputmode="numeric"
            autocomplete="one-time-code"
            />
        </div>
        <div class="pr-3">
          <input
            type="text"
            maxlength="1"
            formControlName="char3"
            #char3
            class="char3 form-control"
            (input)="inputText($event, 'char3', 'char4', 'char2')"
            (paste)="onPaste($event)"
            inputmode="numeric"
            autocomplete="one-time-code"
            />
        </div>
        <div>
          <input
            type="text"
            maxlength="1"
            formControlName="char4"
            #char4
            class="char4 form-control"
            (input)="inputText($event, 'char4', null, 'char3')"
            (paste)="onPaste($event)"
            inputmode="numeric"
            autocomplete="one-time-code"
            />
        </div>
      </div>
    </form>
    <div class="small-text">
      Don’t see a code?
      <button (click)="resend()" [disabled]="loading">Send another one</button>
      @if (enableEditPhone) {
        <span>
          or
          <button (click)="changePhone()">change your phone number</button>
        </span>
      }
    </div>
    <button class="btn btn-primary" (click)="verify()" [disabled]="!form.dirty || !form.valid || loading">
      Verify phone
    </button>
  </div>
</app-modal>
