import { Listing } from '../nav/nav.model';
import { PaymentMethod } from './payment-method.model';

export enum PaymentTypes {
  Shipping = 'shipping',
  Listing = 'listing',
  Photography = 'photography',
  Inspection = 'inspection',
  VehicleReports = 'vehicle reports',
  TitleCheck = 'title-check',
}

export interface ListingPayment
  extends Pick<Listing, 'CarMake' | 'CarModel' | 'RegistrationYear' | 'uploadImages' | '_id' | 'vehicleType'> {
  amountPaid: number;
  paymentMethod: PaymentMethod;
  paymentDate: string;
  type: 'shipping' | 'listing' | 'inspection' | 'photography' | 'vehicle reports' | 'title-check';
  orderId?: string;
  excusedPayment?: boolean;
  listingPaymentType?: 'past-initial' | 'past-closing';
  isCancelled?: boolean;
  status?: string;
}
