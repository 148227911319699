import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { BankService, NotificationService, SellerListingService, UsersService } from 'src/app/core/services';
import { ISellerListing, IUser, PaymentMethod } from 'src/app/models';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';

import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

import { ActivatedRoute, Router } from '@angular/router';
import { FacebookService } from 'src/app/core/services/facebook.service';

@Component({
  selector: 'app-modal-purchase-vehicle-report',
  templateUrl: './modal-purchase-vehicle-report.component.html',
  styleUrls: ['./modal-purchase-vehicle-report.component.scss'],
})
export class ModalPurchaseVehicleReportComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  private stripe: Stripe;
  private elements: StripeElements;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onSuccessfulPayment = new EventEmitter<boolean>();
  @Output() onActionEvent = new EventEmitter<string>();
  @Output() onReportPurchaseSucess = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  @Input() listing: ISellerListing;
  @Input() fromDeal: boolean;

  @Output() onClose = new EventEmitter<boolean>();

  paymentMethods: PaymentMethod[] = [];
  discount = 0.0;
  payAmount = 99.0;
  closingFee = 0.0;
  closingFeeDiscount = 0.0;
  payData = {
    promoCode: '',
    nameOnCard: '',
    number: '',
    expDate: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    zipCode: '',
    listingId: '',
  };

  shareVehicleListing: any;

  user: IUser;
  orderId: '';

  hasDiscount = false;
  showSucessUi = false;
  listingProccessingUi = false;
  listingPostedSuccess = false;

  applyPromocodeLoading = false;
  payLaterAmount: number;
  payNowAmount: number;
  payLoading = false;
  features2: {
    text: string;
  }[];
  paymentElementConfigured = false;

  paymentIntentSecret: string;

  form: UntypedFormGroup;

  readonly stripeInputStyles = {
    base: {
      lineHeight: '1.5',
      fontSize: '1.06rem',
      fontFamily: '"Proxima Nova", Helvetica, Arial, sans-serif',
      color: '#495057',
      fontWeight: 300,
    },
  };

  constructor(
    private readonly notificationService: NotificationService,
    private readonly userService: UsersService,
    private readonly route: ActivatedRoute,
    private readonly bankService: BankService,
    private readonly fb: UntypedFormBuilder,
    private readonly listingService: SellerListingService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly renderer: Renderer2,
    private readonly facebookService: FacebookService,
    private readonly router: Router,
    private readonly sellerListingService: SellerListingService,
    private readonly googleAnalytics: GoogleAnalyticsService,

    private el: ElementRef
  ) {
    this.payNowAmount = this.config.initialFeePrice;
    this.payLaterAmount = this.config.payLaterPrice;
  }

  get hasExistingPaymentMethod() {
    return this.paymentMethods.length > 0;
  }

  ngOnInit() {
    this.bankService
      .getPaymentMethods()
      .pipe(take(1))
      .subscribe((response) => {
        this.paymentMethods = response.data;
        if (this.paymentMethods.length) {
          const defaultPaymentMethod = this.paymentMethods.find((e) => e.default) || this.paymentMethods[0];
          this.form.patchValue({ paymentMethod: defaultPaymentMethod.id });
        }
      });

    this.bankService
      .getProductPrice(this.config.stripeProducts.vehicleReportProductId)
      .subscribe((product: { amount: number }) => {
        this.payAmount = product.amount;
      });
    this.userService.user.subscribe((user) => {
      this.user = user;
    });

    this.modalConfig = {
      name: 'Purchase Vehicle Reports',
      modalDialogClass: 'scrollable',
      headerBg: '#F7F7F7',
      headerLogo: 'assets/autoCheckBlackBookLogo.svg',
      headerRounded: true,
      beforeClose: (isClosedByUser = true) => {
        if (this.payLoading) {
          return false;
        }
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isClosedByUser);
        return true;
      },
    };

    this.form = this.fb.group({
      paymentMethod: [null, []],
    });

    this.loadStripe();
  }

  loadStripe() {
    from(
      loadStripe(this.config.stripeKey, {
        betas: ['process_order_beta_1'],
        apiVersion: '2020-03-02; orders_beta=v3',
      })
    ).subscribe((stripe) => {
      this.stripe = stripe;
    });
  }

  configurePaymentElement() {
    this.bankService
      .chargeCardOneTimePurchase(
        {
          type: 'vehicle reports',
          amount: this.payAmount,
          listingId: this.listing._id,
        },
        false
      )
      .pipe(take(1))
      .subscribe((response: any) => {
        this.orderId = response.id;
        if (!this.hasExistingPaymentMethod) {
          this.openStripeElements(response.secret);
        }
        this.paymentIntentSecret = response.secret;
        this.paymentElementConfigured = true;
      });
  }

  onAddPaymentMethodSuccess(paymentMethod: PaymentMethod) {
    this.paymentMethods = [paymentMethod, ...this.paymentMethods];
    this.form.patchValue({ paymentMethod: paymentMethod.id });
    this.form.markAsDirty();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue && this.listing) {
      this.hasDiscount = false;
      this.modalComponent.open();
      if (!this.paymentElementConfigured) {
        this.configurePaymentElement();
        this.paymentElementConfigured = true;
      }
    }
  }

  addDiscount() {
    this.hasDiscount = true;
  }

  openStripeElements(secret: string) {
    this.elements = this.stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: secret });
    const paymentElement = this.elements.create('payment', { terms: { card: 'never' } });
    paymentElement.mount('#payment-element');
  }

  pay() {
    this.payData.listingId = this.listing._id;
    this.payLoading = true;
    const confirmCallback = async (response) => {
      if (response.error) {
        this.notificationService.notification('error', response.error.message);
        this.payLoading = false;
        this.listingProccessingUi = false;
        return;
      }

      this.addListingReportsToUser();
    };

    if (this.hasExistingPaymentMethod) {
      return this.stripe
        .confirmCardPayment(this.paymentIntentSecret, {
          payment_method: this.form.value.paymentMethod,
        })
        .then(confirmCallback);
    }
    return this.stripe
      .confirmPayment({
        elements: this.elements,
        redirect: 'if_required',
      })
      .then(confirmCallback);
  }

  addListingReportsToUser() {
    this.googleAnalytics.gaEvent({
      event: 'Purchase Reports',
      Login: 'post',
    });
    this.payLoading = false;
    this.onReportPurchaseSucess.emit();
    this.modalComponent.close();
  }

  chooseOption(option) {
    this.onActionEvent.emit(option);
  }

  get displayTerms() {
    return `By clicking pay you authorize PrivateAuto to store your card and process the fee when the final sale is
    complete.`;
  }
}
