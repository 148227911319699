import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

export interface ModalSelectVehicleBuyerListingItem {
  _id: string;
  vin: string;
  img: string;
  RegistrationYear: number;
  CarMake: string;
  CarModel: string;
  Trim: string;
  vehicleType?: string;
  Price?: number;
}

@Component({
  selector: 'app-modal-select-vehicle',
  templateUrl: './modal-select-vehicle.component.html',
  styleUrls: ['./modal-select-vehicle.component.scss'],
})
export class ModalSelectVehicleComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() inquiredlistings: ModalSelectVehicleBuyerListingItem[] = [];
  @Input() favoritelistings: ModalSelectVehicleBuyerListingItem[] = [];
  @Input() usersListings: ModalSelectVehicleBuyerListingItem[] = [];

  @Output() onChooseVehicle = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  selectedListingId = null;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Select vehicle modal',
      modalDialogClass: 'scrollable',
      beforeClose: (isCloseByUser = true) => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isCloseByUser);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  onVehicleClick(id: string) {
    this.selectedListingId = this.selectedListingId !== id ? id : null;
  }

  onChooseVehicleClick() {
    this.onChooseVehicle.emit(this.selectedListingId);
    this.modalComponent.close(false);
  }

  onStartSearchingClick() {
    this.router.navigateByUrl('/search-listings');
    this.modalComponent.close(false);
  }
}
