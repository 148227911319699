import * as moment from 'moment';
import { ISellerListing, ListingCategoryTypes } from '../models';
import { ListingStepNames, getListingsStepsField } from '../config/listing-steps';

const isStepComplete = (listing: ISellerListing, stepName: ListingStepNames) => {
  const config = getListingsStepsField(listing.vehicleType);
  return config[stepName].isComplete(listing);
};

export const isStepOneComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepOne');
};

export const isStepTwoComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepTwo');
};

export const isStepThreeComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepThree');
};

export const isStepFourComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepFour');
};

export const isStepFiveComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepFive');
};

export const isStepSixComplete = (listing: ISellerListing) => {
  return isStepComplete(listing, 'stepSix');
};

export const getListingMainImage = (listing) => {
  const getDefaultImage = (vehicleType) => {
    switch (vehicleType) {
      case ListingCategoryTypes.Boat:
        return 'new-listing-boat.svg';
      case ListingCategoryTypes.RV:
        return 'new-listing-rv.svg';
      default:
        return 'new-listing-car.svg';
    }
  };
  if (listing.uploadImages && listing.uploadImages[0]) {
    return listing.uploadImages[0]?.images;
  } else {
    return `assets/create-listing/${getDefaultImage(listing.vehicleType)}`;
  }
};

export const formatListing = (listing: ISellerListing): ISellerListing => {
  const paymentDate = listing.listingLiveStartDate ? listing.listingLiveStartDate : listing.payment?.date;
  const days90 = moment(paymentDate).utc(true).add(90, 'days');
  const days120 = moment(paymentDate).utc(true).add(120, 'days');
  const days75 = moment(paymentDate).utc(true).add(75, 'days');

  const diff90 = days90.diff(moment(), 'days');
  const diff120 = days120.diff(moment(), 'days');
  const diff75 = days75.diff(moment(), 'days');

  const mainImg = getListingMainImage(listing);
  const stepOneComplete = isStepOneComplete(listing);
  const stepTwoComplete = isStepTwoComplete(listing);
  const stepThreeComplete = isStepThreeComplete(listing);
  const stepFourComplete = isStepFourComplete(listing);
  const stepFiveComplete = isStepFiveComplete(listing);
  const stepSixComplete = isStepSixComplete(listing);

  return {
    ...listing,
    diff90,
    diff120,
    diff75,
    mainImg,
    stepOneComplete,
    stepTwoComplete,
    stepThreeComplete,
    stepFourComplete,
    stepFiveComplete,
    stepSixComplete,
  };
};

interface ConvertDecodedVinInfoToListingResultListing
  extends Pick<
    ISellerListing,
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'mpg'
    | 'DriveType'
    | 'Cylinders'
    | 'Doors'
    | 'Transmission'
    | 'Fuel'
    | 'BodyStyle'
    | 'Engine'
  > {}

interface ConvertDecodedVinInfoToListingResult {
  listing: ConvertDecodedVinInfoToListingResultListing;
  trimOptions: string[];
}

export const convertDecodedVinInfoToListing = (info): ConvertDecodedVinInfoToListingResult => {
  const { data, infoData, trimOptions = [] } = info;
  const { year: RegistrationYear, make: CarMake, model: CarModel } = data.attributes;
  const {
    mpg = null,
    DriveType = null,
    Cylinders = null,
    Doors = null,
    Transmission = null,
    FuelType: Fuel = null,
    BodyStyle = null,
    EngineSize: Engine = null,
  } = infoData;
  const listing: ConvertDecodedVinInfoToListingResultListing = {
    RegistrationYear,
    CarMake,
    CarModel,
    mpg,
    DriveType,
    Cylinders,
    Doors,
    Transmission,
    Fuel,
    BodyStyle,
    Engine,
  };

  return {
    listing,
    trimOptions,
  };
};
