<app-modal #modal [modalConfig]="modalConfig">
  @if (!showSucessUi) {
    <div class="header">Purchase report for</div>
  }
  @if (!showSucessUi) {
    <div class="body">
      @if (listing) {
        <div class="listing-details-header d-flex align-items-center">
          <div class="listing-image">
            <img src="/assets/clipboard.svg" alt="clipboard" />
          </div>
          <div class="listing-details">
            <div class="listing-main-text">{{listing.RegistrationYear}} {{listing.CarMake}} {{listing.CarModel}}</div>
            <div class="listing-sub-text">
              <div class="d-inline-block">Vin: {{ listing.vinNumber }}</div>
            </div>
          </div>
        </div>
      }
      <form class="pay-from mt-4" [formGroup]="form">
        <div class="total-panel mb-4 p-3">
          <div class="top">
            <div class="renew d-flex justify-content-between top-row">
              <div class="d-flex flex-column">
                <span class="pay-label-title pb-2">Instantly get these two reports:</span>
                <ul class="pl-3">
                  <li>Vehicles history report</li>
                  <li>Market values history report</li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div class="total d-flex justify-content-between font-weight-bold">
            <div>Total:</div>
            <div>{{ (payAmount - discount) | currency }}</div>
          </div>
        </div>
        <div class="payment-method-header">Payment method</div>
        @if (hasExistingPaymentMethod) {
          <div class="py-4">
            <app-payment-method-selector
              name="paymentMethod"
              formControlName="paymentMethod"
              [paymentMethods]="paymentMethods"
              (onAddSuccess)="onAddPaymentMethodSuccess($event)"
            ></app-payment-method-selector>
          </div>
        }
        @if (!hasExistingPaymentMethod) {
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
        }
        <div class="payment-terms">{{displayTerms}}</div>
        @if (!payLoading) {
          <button class="btn btn-primary w-100 pay-button mb-4" (click)="pay()">
            <span> Pay {{ (payAmount - discount) | currency }}</span>
          </button>
        }
        @if (payLoading) {
          <div class="w-100 text-center">
            <div class="spinner-border mr-2 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        }
      </form>
    </div>
  }
</app-modal>
