<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mb-4">Sign document</div>
  <div class="body">
    <nav
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      (navChange)="onNavChange($event)"
      [destroyOnHide]="false"
      [activeId]="activeTab"
    >
      <ng-container [ngbNavItem]="tabs.Type">
        <a ngbNavLink class="btn-link">Type</a>
        <ng-template ngbNavContent>
          <div class="type">
            <label for="">Full name</label>
            <input #fullName type="text" class="form-control" (input)="drawFullName($event.target.value)" />
            <canvas #typeSignatureCanvas class="signature mt-3"></canvas>
          </div>
        </ng-template>
      </ng-container>

      <ng-container [ngbNavItem]="tabs.Draw">
        <a ngbNavLink class="btn-link">Draw</a>
        <ng-template ngbNavContent>
          <div class="draw position-relative">
            <canvas #drawSignatureCanvas class="signature mt-2"></canvas>
            <a (click)="onClearSign()" class="clear btn-link position-absolute">Clear</a>
          </div>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-3"></div>

    <button class="btn-primary w-100 mt-3" (click)="onSignClick()">Sign</button>

    <div class="disclaimer mt-3">
      By signing this document with an electronic signature, I agree that such signature will be as valid as handwritten
      signatures to the extent allowed by local law
    </div>
  </div>
</app-modal>
