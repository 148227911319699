<div class="navHeader">
  <nav>
    <a [routerLink]="link" class="backBtn">
      <img src="../../../assets/back-icon.svg" />
    </a>
  </nav>
  <div class="title-container">
    <h2 class="title">{{title}}</h2>
    <ng-content></ng-content>
  </div>
</div>
