import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IUser } from 'src/app/models';
import { ProgressBarStepsItem } from '../progress-bar-steps/progress-bar-steps.component';

@Component({
  selector: 'app-popover-get-fully-verified',
  templateUrl: './popover-get-fully-verified.component.html',
  styleUrls: ['./popover-get-fully-verified.component.scss'],
})
export class PopoverGetFullyVerifiedComponent implements OnInit, OnChanges {
  @Input() user: IUser;
  steps: ProgressBarStepsItem[] = [];

  @Input() ref: ElementRef;
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();

  _isOpen = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { user, isOpen } = changes;

    if (user?.currentValue) {
      this.steps = this.getSteps(user.currentValue);
      this._isOpen = this.isOpen;
    }

    if (isOpen?.currentValue !== undefined) {
      this._isOpen = isOpen.currentValue;
    }
  }

  getSteps(user: IUser): ProgressBarStepsItem[] {
    const { isEmailVerified, isPhoneVerified, isVerified } = user.verification;
    const color = isVerified ? 'blue' : 'green';

    return [
      {
        icon: null,
        color,
        isDone: isEmailVerified,
      },
      {
        icon: !isVerified ? 'assets/verified.svg' : null,
        color,
        isDone: isPhoneVerified,
      },
      {
        icon: 'assets/fully-verified.svg',
        color,
        isDone: isVerified,
      },
    ];
  }

  onPopoverClosed() {
    this.isOpen = this._isOpen;
    this.isOpenChange.emit(this.isOpen);
    this.onClose.emit(this.isOpen);
  }
}
