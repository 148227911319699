<app-modal #modal [modalConfig]="modalConfig">
  @if (dealNow.listing.vehicleType !== 'Boat') {
  <div class="header">Awesome! Let’s confirm the vehicle details</div>
  } @if (dealNow.listing.vehicleType === 'Boat') {
  <div class="header">Awesome! Let’s confirm the boat's details</div>

  }

  <form [formGroup]="form">
    <div class="listing-details-header d-flex align-items-center">
      <div class="listing-image position-relative">
        <app-listing-image
          [src]="f.mainImg.value"
          [vehicleType]="dealNow.listing?.vehicleType"
          [width]="65.75"
          [height]="52"
        ></app-listing-image>
      </div>
      <div class="listing-details">
        <div class="listing-main-text">{{f.yearField.value}} {{f.make.value}} {{f.model.value}}</div>
        <div class="listing-sub-text">
          @if (f.vin.value) {
          <div class="d-inline-block">
            {{dealNow.listing?.vehicleType === 'Boat' ? 'HIN': 'VIN'}}: {{ f.vin.value }}
          </div>
          }
        </div>
        <a class="add-image" (click)="onEditClick()">Edit</a>
      </div>
    </div>

    @if (showVin || editListing ) {
    <div class="mt-2 pb-1">
      <div class="input-label">{{dealNow.listing?.vehicleType === 'Boat' ? 'HIN': 'VIN'}}</div>
      <input
        class="form-control mt-1"
        type="text"
        [placeholder]="dealNow.listing?.vehicleType === 'Boat' ? 'Enter HIN': 'Enter VIN'"
        formControlName="vin"
        autocomplete="off"
      />
    </div>
    }
    <div class="position-relative mt-3 pb-1" [ngClass]="{'d-none': !editListing}">
      <div class="input-label">Year</div>
      <input
        mask="0000"
        class="form-control mt-1"
        type="text"
        formControlName="yearField"
        autocomplete="off"
        #yearField="ngbTypeahead"
        [ngbTypeahead]="search"
        (focus)="focusYear$.next($any($event).target.value)"
        (click)="clickYear$.next($any($event).target.value)"
      />
    </div>
    @if (editListing) {
    <div>
      <div class="mt-2 pb-1">
        <div class="input-label">Make</div>
        <input class="form-control mt-1" type="text" formControlName="make" autocomplete="off" />
      </div>
      <div class="mt-2">
        <div class="input-label">Model</div>
        <input class="form-control mt-1" type="text" formControlName="model" autocomplete="off" />
      </div>
    </div>
    }

    <div>
      @if (dealNow.listing.vehicleType !== 'Boat') {
      <div class="mt-2 pb-1">
        <div class="input-label">Licence Plate</div>
        <input class="form-control mt-1" type="text" formControlName="licencePlate" autocomplete="off" />
      </div>
      } @if (dealNow.listing.vehicleType === 'Boat') {
      <div class="mt-2 pb-1">
        <div class="input-label">Registration Number</div>
        <input class="form-control mt-1" type="text" formControlName="registrationNumber" autocomplete="off" />
      </div>
      }

      <div class="mt-2 pb-1">
        <div class="input-label">State vehicle is registered</div>
        <select formControlName="stateRegistered" class="state form-control form-control-select mt-1">
          <option [ngValue]="null" [disabled]="true" [hidden]="true">Select State</option>
          @for (option of stateOptions; track option) {
          <option [value]="option">{{option}}</option>
          }
        </select>
      </div>
      @if (dealNow.listing.vehicleType === 'Boat') {
      <div class="mt-2 pb-1">
        <div class="input-label">Hours</div>
        <input class="form-control mt-1" type="text" formControlName="hours" autocomplete="off" />
      </div>
      } @if (dealNow.listing.vehicleType !== 'Boat') {

      <div class="mt-2">
        <div class="input-label">Miles</div>
        <input
          mask="separator.2"
          thousandSeparator=","
          class="form-control mt-1"
          type="text"
          formControlName="miles"
          autocomplete="off"
        />
      </div>
      }
    </div>
  </form>

  <button class="btn-primary w-100 mt-3" [disabled]="form.invalid" (click)="confirm()">Confirm and view offer</button>
</app-modal>
