export enum PostHogFeatureFlags {
  ListingAuctionsEnabled = 'ListingAuctionsEnabled',
  ChatDocsShare = 'chat-docs-share',
  ServiceCouponCodes = 'service-coupon-codes',
  ProveVerification = 'prove-verification',
  BottomNavShow = 'bottom-nav-show',
  ProveMobileAuth = 'prove-mobile-auth',
  NextImageProcessor = 'next-image-processor',
  ImageUploadResize = 'image-upload-resize',
  ChatCreationLimit = 'chat-creation-limit',
  MoneyPage2024 = 'money-page-2024',
  YassiTitleCheck = 'yassi-title-check',
  HoldFuturePayment = 'hold-future-payments',
}
