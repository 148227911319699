import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISellerListing } from 'src/app/models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, combineLatest, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { SellerService } from 'src/app/core/services';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';

interface Options {
  title: string;
  subtext: string;
  image: string;
  action: () => void;
}

export interface ModalVintageVehicleOutput
  extends Pick<ISellerListing, 'RegistrationYear' | 'CarMake' | 'CarModel' | 'vinNumber'> {}

type ModalVintageVehicleViews = 'page1' | 'page2';

@AutoUnsubscribe()
@Component({
  selector: 'app-modal-vintage-vehicle',
  templateUrl: './modal-vintage-vehicle.component.html',
  styleUrls: ['./modal-vintage-vehicle.component.scss'],
})
export class ModalVintageVehicleComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() vin = '';
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() setVehicleVintage = new EventEmitter<boolean>();
  @Output() checkTypos = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter<any>();
  @Output() onContinue = new EventEmitter<ModalVintageVehicleOutput>();

  modalConfig: ModalConfig = {};
  displayedView: ModalVintageVehicleViews = 'page1';

  // Page 2
  page2Form: UntypedFormGroup;
  showModelText = false;
  showModelDropdown = false;
  modelOptions: string[] = [];

  options: Options[] = [
    {
      title: 'Check for typos',
      subtext: 'Look for spaces or any wrong characters in your plate or VIN',
      image: 'assets/icons/xyzIcon.svg',
      action: () => {
        this.checkTypos.emit();
        this.modalComponent.close();
      },
    },
    {
      title: 'CPI or vintage',
      subtext: 'My car is older than 1981, a collectible, or an exotic car',
      image: 'assets/car-icons/buggyIcon.svg',
      action: () => {
        this.displayedView = 'page2';
      },
    },
  ];

  initForm$: Subscription;

  get page2F() {
    return this.page2Form.controls;
  }

  constructor(private readonly fb: UntypedFormBuilder, private readonly sellerService: SellerService) {
    this.page2Form = this.fb.group(
      {
        RegistrationYear: [null, [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]*$')]],
        CarMake: [null, Validators.required],
        CarModel: [null, Validators.required],
        vinNumber: [null, Validators.required],
      },
      { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Vintage Vehicle Modal',
      hideLogo: true,
      trackevent: true,
      beforeClose: async () => {
        this.resetPage2Form();
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };

    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, vin } = changes;

    if (isOpen?.currentValue) {
      this.displayedView = 'page1';
      this.modalComponent.open();
    }

    if (vin?.currentValue) {
      this.page2Form.patchValue({ vinNumber: vin.currentValue }, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    // AutoUnsubscribe
  }

  initForm() {
    this.initForm$ = combineLatest([
      this.page2Form.get('RegistrationYear').valueChanges,
      this.page2Form.get('CarMake').valueChanges,
    ])
      .pipe(
        filter(([RegistrationYear, CarMake]) => RegistrationYear && CarMake),
        switchMap(([RegistrationYear, CarMake]) => {
          return this.sellerService.searchModelsByYearAndMake(RegistrationYear, CarMake).pipe(
            catchError(() => {
              return of({ result: [] });
            })
          );
        })
      )
      .subscribe(({ result = [] }) => {
        if (!result.length) {
          this.showModelDropdown = false;
          this.showModelText = true;
          return;
        }

        this.showModelDropdown = true;
        this.modelOptions = result.map(({ name }) => name);
        this.showModelText = false;
      });
  }

  resetPage2Form() {
    this.page2Form.reset();
    this.showModelText = false;
    this.showModelDropdown = false;
    this.modelOptions = [];
  }

  changeView(view: ModalVintageVehicleViews) {
    this.displayedView = view;
  }

  onPage2BackClick() {
    this.displayedView = 'page1';
  }

  onPage2ContinueClick() {
    this.onContinue.emit(this.page2Form.value);
    this.modalComponent.close();
  }

  onChangeModelOption(option: string) {
    this.page2Form.patchValue({ CarModel: option });
    this.page2Form.markAsDirty();
  }
}
