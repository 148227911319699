<div class="feedback-page">
  <a class="d-flex header align-items-center" routerLink="/help-center">
    <img src="assets/right-arrow.svg" alt="back" />
    <div class="mt-1">Back</div>
  </a>
  <div class="title d-flex mt-3">
    <img src="assets/star-black.svg" alt="support" />
    <div class="ml-2">Give us feedback</div>
  </div>
  <div class="mt-3">
    We'd love to hear your feedback to help us improve. We are constantly enhancing our app, and your input is important
    to us. Thank you for helping us make PrivateAuto even better!
  </div>
  <textarea id="feedback-text" placeholder="Write your thoughts.."></textarea>
  <button (click)="submitFeedback()" class="btn-primary btn">Submit feedback</button>
</div>
