import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from './modal.model';
import { ActiveCampaignService } from '../../core/services/active-campaign.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class ModalComponent implements OnInit {
  @ViewChild('modal') private readonly modalContent: TemplateRef<ModalComponent>;
  @Input() public modalConfig: ModalConfig;

  @Output() onShown = new EventEmitter<any>();

  private modalRef: NgbModalRef;
  hideLogo = false;
  hideHeader = false;
  hideClose = false;
  isMobileView = false;
  whiteClose = false;
  headerLogo: string;
  headerBg: string;
  headerRounded: boolean;

  constructor(private readonly modalService: NgbModal, private readonly activeCampaignService: ActiveCampaignService) {}

  ngOnInit(): void {
    window.dispatchEvent(new Event('resize'));

    const mobileConfig = this.isMobileView ? { animation: false, trackevent: true } : { animation: true };

    this.modalConfig = {
      animation: true,
      centered: true,
      name: 'Default Modal',
      ...mobileConfig,
      ...this.modalConfig,

      // Override dismiss behavior with closing
      beforeDismiss: this.modalConfig.beforeClose,
      modalDialogClass: `app-modal ${this.modalConfig.modalDialogClass}`,
    };

    this.hideHeader = this.modalConfig.hideHeader;
    this.hideLogo = this.modalConfig.hideLogo;
    this.hideClose = this.modalConfig.hideClose;
    this.whiteClose = this.modalConfig.whiteClose;
    this.headerLogo = this.modalConfig.headerLogo || 'assets/logo.svg';
    this.headerBg = this.modalConfig.headerBg;
    this.headerRounded = this.modalConfig.headerRounded;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    const isCurrentMobileView = event.target.innerWidth <= 768;
    this.isMobileView = isCurrentMobileView;
  }

  open(): Promise<boolean> {
    if (this.modalConfig.trackevent) {
      this.activeCampaignService.trackModalOpenEvent(this.modalConfig.name).subscribe();
    }

    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, this.modalConfig);
      this.modalRef.result.then(resolve, resolve);
      this.setupListeners(this.modalRef);
    });
  }

  async close(isClosedByUser = true, options = null): Promise<void> {
    if (this.modalConfig.trackevent) {
      this.activeCampaignService.trackModalCloseEvent(this.modalConfig.name).subscribe();
    }
    if (!this.modalConfig.beforeClose || (await this.modalConfig.beforeClose(isClosedByUser, options))) {
      const result = this.modalConfig.onClose && (await this.modalConfig.onClose());
      this.modalRef.close(result);
    }
  }

  setupListeners(ref: NgbModalRef) {
    ref.shown.subscribe(() => {
      this.onShown.emit();
    });
  }
}
