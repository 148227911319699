import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BankService, NotificationService } from 'src/app/core/services';
import {
  Stripe,
  StripeElements,
  loadStripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { PaymentMethod } from 'src/app/models';

@Component({
  selector: 'app-account-add-payment-method',
  templateUrl: './modal-add-payment-method.component.html',
  styleUrls: ['./modal-add-payment-method.component.scss'],
})
export class ModalAddPaymentMethod implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Output() onSuccess = new EventEmitter<PaymentMethod>();
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  private stripe: Stripe;
  private elements: StripeElements;
  private cardNumberElement: StripeCardNumberElement;

  errors: { [key in string]: string } = {};

  @Output() onClose = new EventEmitter<any>();

  loading = false;
  modalConfig: ModalConfig = {};
  form: UntypedFormGroup;
  submitted = false;
  validators: {
    isValid: () => boolean;
    text: string;
  }[] = [];

  get f() {
    return this.form.controls;
  }

  get isBtnEnabled() {
    return false;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly bankService: BankService,
    private readonly notificationService: NotificationService
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.modalConfig = {
      name: 'Add a new card',
      trackevent: true,
      beforeClose: async () => {
        this.isOpen = !this.isOpen;

        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
    };
    this.stripe = await loadStripe(this.config.stripeKey, {
      betas: ['process_order_beta_1'],
      apiVersion: '2020-03-02; orders_beta=v3',
    });
    this.elements = this.stripe.elements({
      appearance: { theme: 'stripe' },
    });
  }

  registerElement<T>(id: string): T {
    const elementOption = {
      classes: {
        invalid: 'is-invalid',
      },
    };
    const element: any = this.elements.create(id as any, elementOption);
    element.mount(`#${id}`);
    element.on('change', (event) => {
      if (event.error) {
        this.errors[id] = event.error.message;
      } else {
        this.errors[id] = '';
      }
    });
    return element;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen.currentValue) {
      this.modalComponent.open();
      this.form.reset();

      try {
        this.cardNumberElement = this.registerElement<StripeCardNumberElement>('cardNumber');
        this.registerElement<StripeCardExpiryElement>('cardExpiry');
        this.registerElement<StripeCardCvcElement>('cardCvc');
      } catch (err) {
        console.error(err);
      }
    }
  }

  addPaymentMethodToUser(paymentMethod: string) {
    return this.bankService.createPaymentMethod({ paymentMethod }).subscribe({
      next: (response: { paymentMethod: PaymentMethod }) => {
        this.notificationService.notification('success', 'Payment method successfully added');
        this.modalComponent.close();
        this.loading = false;
        this.onSuccess.emit(response.paymentMethod);
      },
      error: (err) => {
        const message = err.message || 'Something went wrong.';
        this.notificationService.notification('error', message);
        this.loading = false;
      },
    });
  }

  async onAddCard() {
    this.submitted = true;
    // validate card
    const response = await this.stripe.createToken(this.cardNumberElement, {
      ...(this.form.value.name && {
        name: this.form.value.name,
      }),
      ...(this.form.value.postalCode && {
        address_state: this.form.value.postalCode,
      }),
    });
    if (this.form.invalid || response.error) {
      return;
    }
    this.loading = true;
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.cardNumberElement,
        billing_details: {
          name: this.form.value.name,
          address: {
            postal_code: this.form.value.postalCode,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          this.notificationService.notification('error', result.error.message);
          return;
        }
        this.addPaymentMethodToUser(result.paymentMethod.id);
      });
  }

  closeModal() {
    this.modalComponent.close();
  }
}
