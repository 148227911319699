<div>
  @if (showError) {
  <div>
    <div class="header-error">
      Unfortunately, we were unable to autofill your request at this time. Please provide your information below to
      continue verification
    </div>
  </div>
  }
  <div class="subtitle left">Your information</div>
  <div class="description left">All fields are required unless stated otherwise</div>
  <form [formGroup]="form" (submit)="onVerify()" class="d-flex flex-column" style="margin-top: 24px">
    <div class="d-flex" style="gap: 8px">
      <div class="form-group" style="flex: 1">
        <label for="firstName">First name</label>
        <input id="firstName" type="text" formControlName="firstName" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        @if (submitted && f.firstName.errors) {
        <div class="invalid-feedback">
          @if (f.firstName.errors.required) {
          <div>First name is required</div>
          }
        </div>
        }
      </div>
      <div class="form-group" style="flex: 1">
        <label for="lastName">Last name</label>
        <input id="lastName" type="text" formControlName="lastName" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        @if (submitted && f.lastName.errors) {
        <div class="invalid-feedback">
          @if (f.lastName.errors.required) {
          <div>Last name is required</div>
          }
        </div>
        }
      </div>
    </div>
    @if(isFullAddressVisible) {
    <div class="form-group">
      <label for="firstName">Full address</label>
      <app-places-autocomplete-input placeholder="Enter full address" elementId="location" #autocompleteInput
        (onChangeEvent)="updateLocation($event)" [(inputLocation)]="location"></app-places-autocomplete-input>
    </div>
    } @else {
    <div class="form-group">
      <label for="firstName">Address Line 1</label>
      <input id="address" type="text" formControlName="address" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.address.errors }" placeholder="123 Main St" />
      @if (submitted && f.address.errors) {
      <div class="invalid-feedback">
        @if (f.address.errors.required) {
        <div>Address Line 1 is required</div>
        }
      </div>
      }
    </div>
    <div class="form-group">
      <label for="firstName">Address Line 2</label>
      <input id="extendedAddress" type="text" formControlName="extendedAddress" class="form-control"
        placeholder="Apt, suite, unit, building, floor, etc."
        [ngClass]="{ 'is-invalid': submitted && f.extendedAddress.errors }" />
    </div>
    <div>
      <div class="d-flex" style="gap: 8px">
        <div class="form-group" style="flex: 1">
          <label for="city">City</label>
          <input id="city" type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
          @if (submitted && f.city.errors) {
          <div class="invalid-feedback">
            @if (f.city.errors.required) {
            <div>City is required</div>
            }
          </div>
          }
        </div>
        <div class="form-group" style="flex: 1">
          <label for="region">State</label>
          <select formControlName="region" class="form-control form-control-select"
            [ngClass]="{'is-invalid': submitted && f.region.errors}">
            @for (state of states; track state) {
            <option [value]="state.abbreviation">{{state.abbreviation}}</option>
            }
          </select>
          @if (submitted && f.region.errors) {
          <div class="invalid-feedback">
            @if (f.region.errors.required) {
            <div>State is required</div>
            }
          </div>
          }
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="postal">Zip code</label>
      <input id="postal" type="text" class="form-control" formControlName="postalCode"
        [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" />
      @if (submitted && f.postalCode.errors) {
      <div class="invalid-feedback">
        @if (f.postalCode.errors.required) {
        <div>Zip code is required</div>
        }
      </div>
      }
    </div>
    }
    @if (disablePrimaryFields) {
    <div class="info-read-only mb-3">
      <div class="d-flex flex-column">
        <div class="key">Phone number</div>
        <div class="value">{{form.value.phoneNumber | mask:'(000) 000-0000'}}&#160;</div>
      </div>
      @if(form.value.ssn) {
      <div class="d-flex flex-column">
        <div class="key">SSN</div>
        <div class="value">{{form.value.ssn}}</div>
      </div>
      }
      <div class="d-flex flex-column">
        <div class="key">Date of birth</div>
        <div class="value">{{form.value.birthDate.month?.toString().padStart(2,
          '0')}}/{{form.value.birthDate.day?.toString().padStart(2, '0')}}/{{form.value.birthDate.year}}</div>
      </div>
    </div>
    } @if (!disablePrimaryFields) {
    <div class="form-group">
      <label for="phoneNumber">Phone number</label>
      <input id="phoneNumber" type="tel" formControlName="phoneNumber" mask="(000) 000-0000" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" [readonly]="disablePrimaryFields" />
      @if (submitted && f.phoneNumber.errors) {
      <div class="invalid-feedback">
        @if (f.phoneNumber.errors.required) {
        <div>Phone number is required</div>
        }
      </div>
      }
    </div>
    <div class="form-group">
      <label for="birthDate">Date of birth</label>
      <input class="form-control" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker formControlName="birthDate" dateMask
        [ngClass]="{ 'is-invalid is-invalid-submitted': submitted && f.birthDate.errors }" inputmode="numeric" />
      @if(submitted && f.birthDate.errors) {
      <div class="invalid-feedback">
        <div>{{f.birthDate.errors.message || 'Date of birth required'}}</div>
      </div>
      }
    </div>
    <!-- <div class="form-group">
      <label for="ssn">Social Security Number</label>
      <input id="ssn" type="text" formControlName="ssn" ssnMask class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.ssn.errors }" [readonly]="disablePrimaryFields" />
      @if (submitted && f.ssn.errors) {
      <div class="invalid-feedback">
        <div>{{f.ssn.errors.message || 'Social Security Number is required'}}</div>
      </div>
      }
    </div> -->
    }
    <button [disabled]="isFullAddressVisible" type="submit" class="btn btn-primary w-100">Submit</button>
  </form>
</div>