<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mb-4">Gain trust with verification</div>
  <div class="body mb-3">
    PrivateAuto brings trust and transparency to the private sale. You decide who to work with based on verification.
  </div>
  <div class="d-flex section-head">
    <img src="fully-verified.svg" class="icon" alt="Fully Verified" />
    <div class="p-2">Full verification</div>
  </div>
  <ul class="section-body px-4">
    <li>PrivateAuto Pay active</li>
    <li>Can send and receive money</li>
    <li>Background check / KYC</li>
    <li>Verified SSN</li>
    <li>Completed partial verification</li>
  </ul>
  <div class="d-flex section-head">
    <img src="verified.svg" class="icon" alt="Verified" />
    <div class="p-2">Partial verification</div>
  </div>
  <ul class="section-body px-4">
    <li>Verified driver’s license</li>
    <li>Facial recognition</li>
    <li>Valid phone and email</li>
  </ul>
  <div class="d-flex section-head">
    <img src="not-verified.svg" class="icon" alt="Not Verified" />
    <div class="p-2">Not verified</div>
  </div>
  <ul class="section-body px-4">
    <li>The person hasn’t verified their account yet.</li>
  </ul>
</app-modal>
