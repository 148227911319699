<div>
  <div class="subtitle mb-2">Let’s begin by finding your info</div>
  <div class="description" style="margin-bottom: 40px">
    We can prefill some of this request like your name, address, and contact info for you
  </div>
  <form (submit)="onSubmit()" [formGroup]="form">
    <div class="form-group">
      <label for="last4Ssn">Last 4 of SSN</label>
      <input id="last4Ssn" type="text" formControlName="last4Ssn" mask="0000" class="form-control"
        [ngClass]="{ 'is-invalid is-invalid-submitted': submitted && f.last4Ssn.errors }" />
      @if (submitted && f.last4Ssn.errors) {
      <div class="invalid-feedback">
        <div>Last 4 of ssn is required</div>
      </div>
      }
    </div>

    @if (!isMobileAuthSuccess) {
    <div class="form-group">
      <label for="phoneNumber">Phone number</label>
      <input id="phoneNumber" type="tel" formControlName="phoneNumber" mask="(000) 000-0000" class="form-control"
        [ngClass]="{ 'is-invalid is-invalid-submitted': submitted && f.phoneNumber.errors }" />
      @if (submitted && f.phoneNumber.errors) {
      <div class="invalid-feedback">
        @if (f.phoneNumber.errors.required) {
        <div>Phone number required</div>
        }
      </div>
      }
    </div>
    }
    <div class="w-full d-flex flex-column align-items-center" style="gap:12px">
      <button class="btn btn-primary w-100">Continue</button>
      <button (click)="onClose.emit()" class="btn skip-verification-btn w-100">No thanks, I’ll get verified
        later.</button>
      @if(!isMobileAuthSuccess) {
      <a (click)="onSkipToManualEntry.emit()" class="opted-out">I don't have a mobile number</a>
      }
    </div>
  </form>
</div>