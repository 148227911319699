import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { ProcessInvitationData, SellerListingService } from 'src/app/core/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISellerListing, ListingCategoryTypes } from 'src/app/models';
import { ALLOWED_REGISTERED_YEARS } from '../modal-add-vehicle-buyer/modal-add-vehicle-buyer.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import STATES from 'src/app/statics/states-hash';

@Component({
  selector: 'app-modal-confirm-vehicle-details',
  templateUrl: './modal-confirm-vehicle-details.component.html',
  styleUrls: ['./modal-confirm-vehicle-details.component.scss'],
})
export class ModalConfirmVehicleDetailsComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;

  @Input() isOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onConfirmDetails = new EventEmitter<ISellerListing>();

  @Input() dealNow: ProcessInvitationData;

  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  form: UntypedFormGroup;
  editListing = false;
  stateOptions = STATES.map((state) => state.name);
  showVin = false;

  get f() {
    return this.form.controls;
  }

  @ViewChild('yearField', { static: true }) yearField: NgbTypeahead;
  years: string[] = ALLOWED_REGISTERED_YEARS;
  focusYear$ = new Subject<string>();
  clickYear$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickYear$.pipe(filter(() => !this.yearField.isPopupOpen()));
    const inputFocus$ = this.focusYear$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((input) => {
        return this.years;
      })
    );
  };

  constructor(private readonly fb: UntypedFormBuilder, private readonly sellerListingService: SellerListingService) {
    this.form = fb.group({
      vin: [null, [Validators.required]],
      mainImg: [null],
      yearField: [null, [Validators.required]],
      make: [null, [Validators.required]],
      model: [null, [Validators.required]],
      listingId: [null, [Validators.required]],
      licencePlate: [null],
      stateRegistered: [null, [Validators.required]],
      miles: [null],
      registrationNumber: [null],
      hours: [null],
    });
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Confirm vehicle modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();
      this.setFormValues(this.dealNow.listing);
      this.updateValidators();
    }
  }

  setFormValues(listing: ISellerListing) {
    const {
      vinNumber,
      uploadImages,
      vehicleType,
      RegistrationYear,
      CarMake,
      CarModel,
      _id,
      licencePlate,
      stateRegistered,
      Mileage,
    } = listing;
    this.form.patchValue({
      vin: vinNumber,
      vehicleType,
      yearField: RegistrationYear,
      make: CarMake,
      model: CarModel,
      listingId: _id,
      licencePlate,
      stateRegistered,
      miles: Mileage,
      mainImg: uploadImages[0]?.images,
    });

    if (!vinNumber) {
      this.showVin = true;
    }
  }

  updateValidators() {
    const miles = this.form.get('miles');
    const licencePlate = this.form.get('licencePlate');
    const registrationNumber = this.form.get('licencePlate');

    if (this.dealNow.listing.vehicleType === ListingCategoryTypes.Boat) {
      registrationNumber.get('registrationNumber').setValidators(Validators.required);
    } else if (this.dealNow.listing.vehicleType === ListingCategoryTypes.Auto) {
      miles.get('miles').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
      licencePlate.get('licencePlate').setValidators(Validators.required);
    }

    miles.updateValueAndValidity();
    licencePlate.updateValueAndValidity();
    registrationNumber.updateValueAndValidity();
  }

  onEditClick() {
    this.editListing = true;
  }

  confirm() {
    const { vin, yearField, make, model, licencePlate, stateRegistered, miles, BodyStyle, hours, registrationNumber } =
      this.form.value;
    this.dealNow.listing = {
      ...this.dealNow.listing,
      BodyStyle: BodyStyle === 'Car' ? 'Sedan' : BodyStyle,
      vinNumber: vin,
      RegistrationYear: yearField,
      CarMake: make,
      CarModel: model,
      licencePlate: licencePlate,
      stateRegistered,
      Mileage: miles ?? hours,
      additionalDetails: {
        boat: {
          registrationNumber,
        },
      },
    };

    this.sellerListingService.updateSellerListing(this.dealNow.listing).subscribe((results) => {
      this.onConfirmDetails.emit(this.dealNow.listing);
      this.modalComponent.close();
    });
  }
}
