import { Component } from '@angular/core';
import { NotificationService, UsersService } from '../../core/services';

@Component({
  selector: 'app-give-feedback',
  templateUrl: './give-feedback.component.html',
  styleUrl: './give-feedback.component.scss',
})
export class GiveFeedbackComponent {
  constructor(private readonly notificationService: NotificationService, private readonly usersService: UsersService) {}
  submitFeedback() {
    const feedbackTextarea = document.getElementById('feedback-text') as HTMLTextAreaElement;
    if (feedbackTextarea.value === '') {
      this.notificationService.notification('warning', 'Must enter feedback to submit');
      return;
    }

    this.usersService.submitFeedback(feedbackTextarea.value).subscribe((res) => {
      this.notificationService.notification('success', 'Successfully submitted feedback');
    });
    feedbackTextarea.value = '';
  }
}
