@if (showError) {
  <div class="d-flex justify-content-center align-items-center pt-5 px-4">
    <div>
      <div class="img-cnt">
        <div class="mb-2">
          <img src="../assets/Logo%20(1).png" alt="" width="90" height="78" />
        </div>
        <div class="mb-4">
          <img src="../assets/Name.png" width="400" height="80" alt="PrivateAuto" />
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <h4>Something is not right. Please reload the page</h4>
            <button (click)="reload()" class="btn btn-primary ml-2">Reload</button>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <app-nav (onLogout)="onLogout()">
    <router-outlet></router-outlet>
  </app-nav>
}

<ng-template #app>
  <app-nav (onLogout)="onLogout()">
    <router-outlet></router-outlet>
  </app-nav>
</ng-template>

<app-modal-session-timeout [(isOpen)]="showSessionTimeout"></app-modal-session-timeout>
