/* eslint-disable no-underscore-dangle */
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { IDealNowDeal, IDealNowDealTypes, ISellerListing, IUser } from 'src/app/models';
import { pluck } from 'rxjs/operators';

interface CreateDealNowDealARequest {
  listingId: string;
}

interface CreateDealNowDealBCRequest {
  year: number;
  make: string;
  model: string;
  price: number;
  listingId?: string;
  vin?: string;
}

interface CreateDealNowDealAResponse {
  success: boolean;
  message: string;
  link: string;
}

interface CreateDealNowDealBCResponse {
  success: boolean;
  message: string;
  link: string;
  listingId: string;
}

export interface ProcessInvitationData extends IDealNowDeal {
  initiatedByName: string;
  listing: ISellerListing;
  externalListing: boolean;
}

interface ProcessInvitationResponse {
  success: boolean;
  message: string;
  type: IDealNowDealTypes;
  data: ProcessInvitationData;
}

interface FinalizeInvitationResponse {
  success: boolean;
  message: string;
}

export type GetInquiredListingItem = Pick<
  ISellerListing,
  | '_id'
  | 'RegistrationYear'
  | 'CarMake'
  | 'CarModel'
  | 'vinNumber'
  | 'uploadImages'
  | 'Trim'
  | 'status'
  | 'vehicleType'
  | 'Price'
>;

interface GetInquiredListingsResponse {
  listings: GetInquiredListingItem[];
}

export interface GetDealsResponseItem extends Omit<IDealNowDeal, 'initiatedBy'> {
  initiatedBy: IUser;
  listing: Pick<
    ISellerListing,
    | '_id'
    | 'status'
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'Price'
    | 'Mileage'
    | 'uploadImages'
    | 'displayType'
    | 'originalSourceInfo'
    | 'slug'
    | 'vinNumber'
    | 'vehicleType'
    | 'additionalDetails'
  >;
  externalListing: boolean;
  linkShared: boolean;
  offerPrice: number;
}

interface GetDealsResponse {
  deals: GetDealsResponseItem[];
}

export interface GetListingsResponseItem extends IDealNowDeal {
  listing: Pick<
    ISellerListing,
    | '_id'
    | 'status'
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'Price'
    | 'Mileage'
    | 'uploadImages'
    | 'displayType'
    | 'originalSourceInfo'
    | 'slug'
    | 'vinNumber'
  >;
}

interface GetListingsResponse {
  listings: GetListingsResponseItem[];
}

@Injectable({
  providedIn: 'root',
})
export class DealNowDealService {
  private baseUrl: string;

  constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly config: IAppConfig) {
    this.baseUrl = `${this.config.apiUrl}/deal-now-deal`;
  }

  createDealNowDealA(data: CreateDealNowDealARequest): Observable<CreateDealNowDealAResponse> {
    return this.http.post<CreateDealNowDealAResponse>(`${this.baseUrl}/types/${IDealNowDealTypes.A}`, data);
  }

  createDealNowDealB(data: CreateDealNowDealBCRequest): Observable<CreateDealNowDealBCResponse> {
    return this.http.post<CreateDealNowDealBCResponse>(`${this.baseUrl}/types/${IDealNowDealTypes.B}`, data);
  }

  processInvitation(invitationId: string): Observable<ProcessInvitationResponse> {
    return this.http.post<ProcessInvitationResponse>(`${this.baseUrl}/invitation`, { invitationId });
  }

  finalizeInvitation(invitationId: string): Observable<FinalizeInvitationResponse> {
    return this.http.post<FinalizeInvitationResponse>(`${this.baseUrl}/finalize`, { invitationId });
  }

  getInquiredListings() {
    return this.http.get<GetInquiredListingsResponse>(`${this.baseUrl}/listings/inquired`).pipe(pluck('listings'));
  }

  getDeals() {
    return this.http.get<GetDealsResponse>(`${this.baseUrl}/deals`).pipe(pluck('deals'));
  }

  getListings() {
    return this.http.get<GetListingsResponse>(`${this.baseUrl}/listings`).pipe(pluck('listings'));
  }

  updateSharedListing(dealId) {
    return this.http.get(`${this.baseUrl}/${dealId}`);
  }

  deleteDealNowDeal(dealId) {
    return this.http.delete(`${this.baseUrl}/${dealId}`);
  }

  createShareableLink(DealNowId) {
    return `${this.config.websiteUrl}/deal-now/${DealNowId}`;
  }
}
