import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

declare let Beacon;

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit {
  constructor(private readonly location: Location) {}

  ngOnInit(): void {
    Beacon('init', '239c3036-a89d-40a9-86c3-41b3dd085f31');
  }

  openBeacon(params = '/') {
    Beacon('open');
    Beacon('navigate', params);
  }

  backClick() {
    this.location.back();
  }
}
