import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils';

export class ParamterHashInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (['get', 'post', 'put', 'delete'].includes(req.method.toLowerCase())) {
      const XPA = Utils.getParamterHash(req.urlWithParams, req.body || {});
      const authReq = req.clone({
        headers: req.headers.set('X-PA', XPA).set('x-client', window.navigator.userAgent),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
