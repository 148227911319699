<app-modal #modal [modalConfig]="modalConfig">
  <div customHeader class="header d-flex align-items-center justify-content-between p-4">
    @if (isAutofilled && verificationStep === 'verification-edit') {
    <button style="z-index: 10;" (click)="onBack()">
      <img src="assets/back-arrow.svg" />
    </button>
    }
    <div class="title">Quick identity verification</div>

    <button class="close ml-auto" (click)="skipVerification()">
      <img src="assets/close.svg" alt="" />
    </button>
  </div>
  <div class="body">
    <div class="inner-container">
      @if(verificationStep === 'mobile-auth') {
      <app-prove-mobile-auth (onSuccess)="onMobileAuthSuccess($event)"></app-prove-mobile-auth>
      }
      @if (verificationStep === 'verification-start') {
      <app-start-verification (onClose)="skipVerification()" (onSkipToManualEntry)="onManualEntry()"
        (onSuccess)="onVerificationSent($event)" [form]="form" [isMobileAuthSuccess]="isMobileAuthSuccess"
        (onMobileAuthPrefillSuccess)="handlePrefillResponse($event)"></app-start-verification>
      }
      @if (verificationStep === 'verification-sent') {
      <app-verification-link-sent (onSkipToManualEntry)="onManualEntry()" (onSuccess)="onVerficationClicked()"
        [session]="onboardingSession" [phoneEnding]="phoneEnding"></app-verification-link-sent>
      }
      @if (verificationStep === 'verification-review') {
      <app-review-information (onEdit)="onEditInformation()" (onContinue)="onSubmitWithoutChanges()"
        [form]="identityform"></app-review-information>
      }
      @if (verificationStep === 'verification-edit') {
      <app-edit-information [disablePrimaryFields]="isAutofilled" [showError]="showManualEntryError"
        (onContinue)="onSubmitIdentityForm()" [form]="identityform"></app-edit-information>
      }
      @if (verificationStep === 'verification-failed') {
      <app-verification-failed (onClose)="skipVerification()"></app-verification-failed>
      }
    </div>
  </div>
</app-modal>