<form [formGroup]="form">
  <div class="vin-box m-2">
    <div class="vin-box-field pt-4">
      <div class="input-label">Vehicle type</div>
      <div ngbDropdown class="btn-dropdown">
        <button
          class="details-input form-control d-flex align-items-center justify-content-between text-left"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          {{ form.get('vehicleType').value }}

          <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
        </button>
        <div ngbDropdownMenu class="dropdown-container" aria-labelledby="dropdownBasic1">
          @for (option of sortOptions; track option) {
          <button
            ngbDropdownItem
            class="dropdown px-3"
            [disabled]="option == 'Boat'"
            (click)="changeVehicleType(option)"
          >
            {{ option }} @if (option == 'Boat') {
            <img class="pl-4" alt="Coming Soon" src="assets/comingSoonIcon.svg" />
            }
          </button>
          }
        </div>
      </div>
    </div>

    <div class="vin-box-field">
      <div class="input-label">State vehicle is registered</div>
      <select
        formControlName="state"
        class="state form-control form-control-select"
        [ngClass]="{'need-input': !stateFeildFilledOut}"
      >
        <option [ngValue]="null" [disabled]="true" [hidden]="true">Select State</option>
        @for (option of sortStates; track option) {
        <option [value]="option">{{option}}</option>
        }
      </select>
    </div>

    <div class="vin-box-field d-flex mt-4">
      @for (vehicleCategory of lookupOptions; track vehicleCategory) {
      <button
        class="ctrls w-100 h-100"
        [class.active]="activeTab === vehicleCategory"
        (click)="changeTab(vehicleCategory)"
      >
        <a
          [ngClass]="vehicleCategory === 'RV' || vehicleCategory === 'Boat' ? 'grayed-out-text text-decoration-none' : 'text-dark text-decoration-none' "
          >{{vehicleCategory}}</a
        >
      </button>
      }
    </div>

    <ng-content></ng-content>
    @if (!isLicensePlateLookUp) {
    <div class="vin-box-field mt-4">
      <div class="vin-entry-block">
        <input
          class="vin-entry form-control"
          [readonly]="isListingLive"
          type="text"
          name="vin-entry"
          formControlName="vin"
          [ngClass]="{'disabled-feilds' : isListingLive, 'need-input' : checkTyposUi}"
        />
        @if (f.vin.dirty) {
        <img
          [src]="f.vin.valid? 'assets/VerifiedVin.svg' : 'assets/vinError.svg'"
          class="vin-svg mt-1"
          alt="vin verification"
        />
        }
      </div>
    </div>
    } @if (vinIsShort && !isLicensePlateLookUp && searchPressed) {
    <div class="vin-vintage mb-3">VIN too short. VIN should be 17 digits.</div>
    } @if (isLicensePlateLookUp) {
    <div class="vin-box-field mt-4">
      <div class="vin-entry-block">
        <input
          class="vin-entry form-control"
          type="text"
          formControlName="licencePlate"
          [ngClass]="{'need-input':  checkTyposUi}"
        />
        @if (f.licencePlate.dirty) {
        <img
          [src]="f.licencePlate.valid? 'assets/VerifiedVin.svg' : 'assets/vinError.svg'"
          class="vin-svg mt-1"
          alt="license verification"
        />
        }
      </div>
    </div>
    }

    <div class="vin-box-field mx-4 mt-4">
      <button class="btn btn-primary w-100" type="submit" name="submit" value="search" (click)="lookUpListingInfo()">
        Find my vehicle
      </button>
    </div>
    @if (!vinIsShort && !isListingLive) {
    <div class="vin-box-field vin-note mx-4">
      <p>A valid VIN is required to list with PrivateAuto.</p>
      <a
        href="https://privateauto.com/blog/where-is-my-vin-number/"
        rel="noopener"
        target="_blank"
        style="text-decoration: underline"
        >Learn more
      </a>
    </div>
    } @if (isListingLive) {
    <div class="vin-note mt-4">
      <p>Your vin number cannot be edited once your listing is live</p>
    </div>
    }
  </div>
</form>

<app-modal-vintage-vehicle
  [vin]="f.vin.value"
  [(isOpen)]="showModalVintageVehicle"
  (onContinue)="onVintageVehicleSave($event)"
  (checkTypos)="checkTypos()"
></app-modal-vintage-vehicle>
