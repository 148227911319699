import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';

@Component({
  selector: 'app-verification-denied-modal',
  templateUrl: './verification-denied-modal.component.html',
  styleUrls: ['./verification-denied-modal.component.scss'],
})
export class VerificationDeniedModalComponent implements OnInit {
  @Input() verificationsDenied = false;
  @Input() fundsDenied = false;
  modalConfig: ModalConfig = {};

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
