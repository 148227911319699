import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { ListingPayment, PaymentMethod } from 'src/app/models';
import { SellerListingService } from './seller-listing.service';
import { PosthogService } from './posthog.service';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  @Output() onViewTask: EventEmitter<null> = new EventEmitter();
  apiUrl: string;
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly listingService: SellerListingService,
    private readonly posthogService: PosthogService
  ) {}

  createStripeSession(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post<{ secret: string }>(`${this.config.apiUrl}/stripe/checkout`, data, { headers });
  }

  chargeCardOneTimePurchase(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post<{ secret: string }>(`${this.config.apiUrl}/stripe/immediate-charge`, data, { headers });
  }

  getProductPrice(productId) {
    return this.http.post<{ amount: number }>(`${this.config.apiUrl}/stripe/product-price`, { productId });
  }

  createPaymentMethod(data) {
    return this.http.post(`${this.config.apiUrl}/stripe/payment-method`, data);
  }

  updateListingPaymentMethod(data) {
    return this.http.post(`${this.config.apiUrl}/stripe/payment-method/listing`, data);
  }

  updatePaymentMethod(id: string, data) {
    return this.http.put(`${this.config.apiUrl}/stripe/payment-method/${id}`, data);
  }

  removePaymentMethod(id: string) {
    return this.http.delete(`${this.config.apiUrl}/stripe/payment-method/${id}`);
  }

  setDefaultPaymentMethod(data) {
    return this.http.post(`${this.config.apiUrl}/stripe/payment-method/default`, data);
  }

  getPaymentMethods() {
    return this.http.get<{ data: PaymentMethod[] }>(`${this.config.apiUrl}/stripe/payment-method`);
  }

  getPaymentActivity() {
    return this.http.get<{
      pastPayments: ListingPayment[];
      futurePayments: ListingPayment[];
      excusedPayments: ListingPayment[];
      cancelledPayments: ListingPayment[];
    }>(`${this.config.apiUrl}/stripe/payment-activity`);
  }

  setupCardPayment(data, hideLoaderInterceptor = false) {
    if (
      this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.HoldFuturePayment) &&
      ['shipping', 'inspection', 'title-check', 'photography'].includes(data.type)
    ) {
      return this.setupCardWithHold(data, hideLoaderInterceptor);
    }
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post<any>(`${this.config.apiUrl}/stripe/card`, data, { headers });
  }

  setupCardWithHold(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post<any>(`${this.config.apiUrl}/stripe/card-hold`, data, { headers });
  }

  async handleStripeSubmit(
    stripe: Stripe,
    elements: StripeElements,
    secret: string,
    hasExistingPaymentMethod: boolean,
    paymentMethodId?: string
  ) {
    if (this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.HoldFuturePayment)) {
      if (hasExistingPaymentMethod) {
        return stripe.confirmCardPayment(secret, {
          payment_method: paymentMethodId,
        });
      } else {
        return stripe.confirmPayment({
          elements,
          redirect: 'if_required',
        });
      }
    } else {
      if (hasExistingPaymentMethod) {
        return stripe.confirmCardSetup(secret, {
          payment_method: paymentMethodId,
        });
      } else {
        return stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });
      }
    }
  }
}
