import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  PaymentMethodResult,
  Stripe,
  StripeCardCvcElement,
  StripeCardElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements,
  loadStripe,
} from '@stripe/stripe-js';
import { from, throwError } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { NotificationService } from 'src/app/core/services';

@Component({
  selector: 'app-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
})
export class StripeFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() btnTxt = 'Save and use card';
  @Output() onSubmit = new EventEmitter<PaymentMethodResult>();

  loading = false;
  card: StripeCardElement;
  stripe: Stripe;
  elements: StripeElements;
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;

  form: UntypedFormGroup;
  f: UntypedFormGroup['controls'];
  errors: { [key in string]: string } = {};

  get stripeElementErrors() {
    return Object.values(this.errors).some((e) => !!e);
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private el: ElementRef,
    private readonly notificationService: NotificationService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      cardNumber: [null, Validators.required],
      cardExpiry: [null, Validators.required],
      cardCvc: [null, Validators.required],
      postalCode: [null, [Validators.required, Validators.maxLength(5), Validators.pattern(/^\d{5}(?:[- ]?\d{4})?$/)]],
    });
    this.f = this.form.controls;
  }

  ngAfterViewInit(): void {
    this.initializeStripe();
  }

  ngOnDestroy(): void {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }

  initializeStripe() {
    from(
      loadStripe(this.config.stripeKey, {
        betas: ['process_order_beta_1'],
        apiVersion: '2020-03-02; orders_beta=v3',
      })
    ).subscribe((stripe) => {
      this.stripe = stripe;

      this.elements = this.stripe.elements({
        appearance: { theme: 'stripe' },
      });

      this.cardNumber = this.registerElement<StripeCardNumberElement>('cardNumber');
      this.cardExpiry = this.registerElement<StripeCardExpiryElement>('cardExpiry');
      this.cardCvc = this.registerElement<StripeCardCvcElement>('cardCvc');
    });
  }

  registerElement<T>(id: string): T {
    const elementOption = {
      base: {
        color: '#212529',
      },
      classes: {
        invalid: 'is-invalid',
      },
    };
    const element: any = this.elements.create(id as any, elementOption);
    const el = this.el.nativeElement.querySelector(`#${id}`);
    element.mount(el);

    element.on('change', (event) => {
      if (event.error) {
        this.errors[id] = event.error.message;
        this.form.controls[id].setValue(null);
      }

      if (event.complete) {
        this.errors[id] = '';
        // For button disabling purposes
        this.form.controls[id].setValue('not empty');
      }
    });
    return element;
  }

  onSubmitClick() {
    this.loading = true;
    const { name, postalCode: address_state } = this.form.value;
    from(
      this.stripe.createToken(this.cardNumber, {
        name,
        address_state,
      })
    )
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        }),
        map((result) => {
          if (result.error) {
            throwError(result.error);
          }

          return result;
        }),
        switchMap((result) => {
          return this.stripe.createPaymentMethod({
            type: 'card',
            card: { token: result.token.id },
          });
        })
      )
      .subscribe({
        next: (result) => {
          this.onSubmit.emit(result);
        },
        error: (e) => {
          this.notificationService.notification('error', e.message);
        },
      });
  }
}
