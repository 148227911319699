import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, filter, tap, switchMap } from 'rxjs/operators';
import { ChatKittyService } from 'src/app/core/services/chatKitty.service';
import { UsersService } from 'src/app/core/services/users.service';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { DealsStatuses, IDeal, IDealBase, IDealTitleTypes } from 'src/app/deals/deals.models';
import { UsallianceLoanStatuses } from './usalliance.service';

interface StartDealRequest {
  offerId: string;
  chatId: string;
}

interface ConfirmDealRequest {
  dealId: string;
  price: number;
  odometer: number;
  disclosure: string;
  payment: {
    privateAutoPay: { amount: number };
    cash: { amount: number };
    crypto: {
      btc: { amount: number };
    };
  };
}

interface SaveEntityPayload {
  dealId: string;
  type: 'buyer' | 'seller';
  entity: {
    entityName: string;
    isAuthorizedSigner: boolean;
    signerTitle: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  constructor(
    private readonly http: HttpClient,
    private readonly chatKittyService: ChatKittyService,
    private readonly usersService: UsersService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {}

  private readonly dealSummarySubject = new BehaviorSubject<IDeal>(null);
  get dealSummary() {
    return this.dealSummarySubject.asObservable().pipe(filter(Boolean)) as Observable<IDeal>;
  }

  updateDealSummary(updatedDealSummary) {
    const formattedDeal = this.formatDealSummary(updatedDealSummary);
    this.dealSummarySubject.next(formattedDeal);
  }

  getVehicleHistoryReport(listingId) {
    return this.http.get(`${this.config.apiUrl}/vehicles/autocheck/${listingId}`);
  }

  getMySoldDealByListingId(listingId, buyer = '') {
    return this.http.get(`${this.config.apiUrl}/deals/listing/${listingId}`, { params: { buyer } });
  }

  getDealSummary(id: string) {
    return this.http.get(`${this.config.apiUrl}/deals/${id}`).pipe(
      switchMap((deal: IDeal) => {
        const { seller, buyer } = deal;
        return combineLatest([
          this.usersService.configureUser(seller.info),
          this.usersService.configureUser(buyer.info),
        ]).pipe(
          map(([sellerInfo, buyerInfo]) => {
            return {
              ...deal,
              seller: { ...deal.seller, info: sellerInfo },
              buyer: { ...deal.buyer, info: buyerInfo },
            };
          })
        );
      }),
      map((deal: IDeal) => this.formatDealSummary(deal)),
      tap((deal) => {
        this.dealSummarySubject.next(deal);
      })
    );
  }

  formatDealSummary(deal: IDealBase): IDeal {
    const { buyer, seller, allDocumentsSigned, loan } = deal;
    buyer.confirmedDate = buyer.confirmedDate && new Date(buyer.confirmedDate);
    seller.confirmedDate = seller.confirmedDate && new Date(seller.confirmedDate);

    const isTitleAcknowledged = !!(seller.titleAcknowledgedDate && buyer.titleAcknowledgedDate);
    const { front, back, completedDate } = deal.listing?.titleAttachment || {};
    const isTitleAttached = !!(front && back && completedDate && loan);
    const type = loan ? IDealTitleTypes.Attachment : IDealTitleTypes.Acknowledgement;
    const isReviewed = type === IDealTitleTypes.Attachment ? isTitleAttached : isTitleAcknowledged;
    const title = { type, isReviewed };

    const isStepOneComplete = !!(buyer.confirmedDate && seller.confirmedDate);
    const isStepTwoComplete = isStepOneComplete && !!(allDocumentsSigned && isReviewed);
    const isStepThreeComplete = !!seller.confirmPaymentDate;

    // if users already completed documents step just proceed
    const isAddonServicesStepComplete = Boolean(
      buyer?.addonServicesConfirmedDate && seller?.addonServicesConfirmedDate
    );
    const isPaymentTransferStarted = this.checkPaymentIfTransferStarted(deal);
    const isDealLoanFinancedAndNotSold =
      [UsallianceLoanStatuses.APPROVED, UsallianceLoanStatuses.FUNDED].includes(deal.loan?.status) &&
      deal.status !== DealsStatuses.Sold;

    const formattedDeal = {
      ...deal,
      isStepOneComplete,
      isStepTwoComplete,
      isStepThreeComplete,
      isAddonServicesStepComplete,
      isPaymentTransferStarted,
      title,
      isDealLoanFinancedAndNotSold,
    };
    return formattedDeal;
  }

  checkPaymentIfTransferStarted(deal: IDealBase): boolean {
    const {
      privateAutoPay,
      cash,
      loan,
      crypto: { btc },
    } = deal.payment;

    const availableAllocations = [];

    if (privateAutoPay.amount && privateAutoPay.transferDate) {
      availableAllocations.push(privateAutoPay.transferDate);
    }

    if (cash.amount && cash.transferDate) {
      availableAllocations.push(cash.transferDate);
    }

    if (btc.amount && btc.transferDate) {
      availableAllocations.push(btc.transferDate);
    }

    if (loan?.amount && loan?.transferDate) {
      availableAllocations.push(loan.transferDate);
    }

    return !!availableAllocations.length && availableAllocations.every(Boolean);
  }

  startDeal(data: StartDealRequest) {
    return this.http.post(`${this.config.apiUrl}/deals/start`, data).pipe(
      tap(() => {
        const { offerId: _id, chatId } = data;
        return this.chatKittyService.sendOfferMessage({ _id, chatId }, true).subscribe();
      })
    );
  }

  confirmDeal(data: ConfirmDealRequest) {
    return this.http.post(`${this.config.apiUrl}/deals/confirm`, data);
  }

  cancelDeal(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/cancel`, { dealId });
  }

  confirmPayment(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/confirm-payment`, { dealId });
  }

  startTransfer(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/start-transfer`, { dealId });
  }

  acknowledgeTitle(dealId: string) {
    return this.http.put(`${this.config.apiUrl}/deals/acknowledge-title`, { dealId });
  }

  confirmAddonServices(dealId: string) {
    return this.http.put(`${this.config.apiUrl}/deals/confirm-addon-services`, { dealId });
  }

  hasCurrentPendingDeal(listingId: string) {
    return this.http.post(
      `${this.config.apiUrl}/deals/listing/${listingId}`,
      { listingId },
      {
        headers: {
          'X-No-Loader': '1',
        },
      }
    );
  }

  /**
   * Settle balance with the current payment method
   * @param tokenId - payment method
   */
  settleBalance(tokenId) {
    return this.http.post<{ message: string; success: boolean }>(`${this.config.apiUrl}/deals/settle-balance`, {
      tokenId,
    });
  }

  getDuplicateDeal(listing) {
    return this.http.get(`${this.config.apiUrl}/deals/duplicate`, { params: { listing } });
  }

  getDuplicateDealByListingDetails(year, make, model, vin?) {
    return this.http.get(`${this.config.apiUrl}/deals/duplicate`, { params: { year, make, model, vin } });
  }

  /**
   * If the user has an approved loan, and payment allocation for loan is set, use that instead.
   * If the payment allocation for loan is not set, use the approved loan amount.
   * @param deal
   * @returns
   */
  getPrivateAutoPayAmountToSend(deal: IDeal): number {
    const { loan, payment } = deal;
    const paPayAmount = payment.privateAutoPay?.amount;
    const usaLoanAmount = (loan?.amount ? payment.loan?.amount : loan?.amount) || 0;
    return paPayAmount + +usaLoanAmount;
  }

  saveEntity(payload: SaveEntityPayload) {
    const { dealId, entity, type } = payload;
    return this.http.post<{ success: boolean; message: string }>(`${this.config.apiUrl}/deals/${dealId}/entity`, {
      type,
      entity,
    });
  }
}
